import React from "react";
import Images from "../../images";
import "./barpopup.css";
const ChartBarPopup = ({
  barPosition,
  barData,
  onClose,
  viewComfort,
  isMobile,
  isMac,
  IsFourK,
  locationData,
  openSection,
  isPortrait,
}) => {
  const shieldslogo = {
    0: [{ logoname: "LoĒ-180", icon: Images.Logo_180 }],
    1: [
      { logoname: "LoĒ²-272", icon: Images.Logo_272 },
      { logoname: "LoĒ²-270", icon: Images.Logo_270 },
    ],
  };
  const shields = shieldslogo[barData.activeIndex] || [];
  let position = IsFourK
    ? barPosition.left + 79
    : isMac
    ? barPosition.left - 30
    : barPosition.left;

  let barDatalabel = "";
  if (barData?.label === "HSLE") {
    barDatalabel = "High Solar Gain LoĒ Products";
  } else if (barData?.label === "MSLE") {
    barDatalabel = "Medium Solar Gain LoĒ Products";
  } else if (barData?.label === "LSLE") {
    barDatalabel = "Low Solar Gain LoĒ Products";
  } else {
    barDatalabel = "Primary Coating: " + barData?.primary_Coatings;
  }

  return (
    <React.Fragment>
      <div
        className={
          barData && isMobile ? "bar-popup-overlay show" : "bar-popup-overlay"
        }
      ></div>
      <div
        className={barPosition.lastIndex ? "bar-popup reversetr" : "bar-popup"}
        style={{
          left: `${position}px`,
        }}
      >
        <button type="button" className="close-popup" onClick={onClose}>
          &times;
        </button>
        <div className="popup-inner">
          <div className="bar-value">
            <h4>{barData.value}%</h4>
            <p>Savings over traditional 2-pane clear glass</p>
          </div>
          <div className="bardata">
            <h6>{barDatalabel}</h6>
            <div className="bardata-inner logos-loe">
              <ul className="image-container">
                {shields.map((logo, index) => (
                  <li
                    key={index}
                    className={barPosition.lastIndex ? "logo_height" : ""}
                  >
                    <img src={logo.icon} alt="img" />
                    <div className="logo-name">{logo.logoname}</div>
                  </li>
                ))}
                {locationData.energy_star_zone === "Northern" &&
                openSection === 0 &&
                barPosition.lastIndex ? (
                  <li>
                    <img src={Images.Logo_366} alt="img" />
                    <div className="logo-name">LoĒ³-366</div>
                  </li>
                ) : barPosition.lastIndex ? (
                  <li className={barPosition.lastIndex ? "logo_height" : ""}>
                    <img src={Images.shildLastTextWithout} alt="img" />
                    <div className="shildlast-logo-name">
                      <div className="logo-name">LoĒ³-366</div>
                      <div className="logo-name">LoĒ-452+</div>
                      <div className="logo-name">LoĒ²-240</div>
                      <div className="logo-name">LoĒ³-340</div>
                    </div>
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </div>
          </div>
          <div className="bardata">
            <h6>{barData.productType}</h6>
            <div className="bardata-inner coating-img">
              <ul>
                <li>
                  {barData.productType === "2 pane w/1 LoĒ" && (
                    <img
                      src={Images.panecoat}
                      alt="img"
                      className="first-coating-img"
                    />
                  )}
                  {barData.productType === "2 pane w/1 LoĒ+i89" && (
                    <img
                      src={Images.paneloe}
                      alt="img"
                      className="second-coating-img"
                    />
                  )}
                  {barData.productType === "3 pane w/2 LoĒ" && (
                    <img
                      src={Images.pane_3}
                      alt="img"
                      className="third-coating-img"
                    />
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <button
          className="comfort-anylysis"
          type="button"
          onClick={() => viewComfort("Comfort", barData)}
        >
          View Comfort Analysis
        </button>
      </div>
    </React.Fragment>
  );
};

export default ChartBarPopup;
